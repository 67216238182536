
import Slide from '~/components/slide/Slide.vue'
import ChevronIcon from '~/assets/icons/chevron.svg'

export default {
  name: 'Slider',
  components: {
    ChevronIcon,
    Slide
  },
  props: {
    slides: {
      type: Array,
      required: true
    },
    mobileImageWidth: {
      type: String,
      default: null
    },
    desktopImageWidth: {
      type: String,
      default: null
    },
    bgColor: {
      type: String,
      default: null
    },
    borderRadius: {
      type: String,
      default: null
    },
    aspectRatioDesktop: {
      type: String,
      default: null
    },
    aspectRatioMobile: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isOnStart: true,
      isOnEnd: true,
      haveInteracted: false,
    }
  },
  computed: {
    mobileImageWidthToWidthOfViewport () {
      if (typeof this.mobileImageWidth !== 'string' || this.mobileImageWidth === '') {
        return 100
      }
      if (this.mobileImageWidth.endsWith('vw')) {
        return parseFloat(this.mobileImageWidth.slice(0, -2))
      }
      if (this.mobileImageWidth.endsWith('%')) {
        return parseFloat(this.mobileImageWidth.slice(0, -1))
      }
      return 100
    },
    desktopImageWidthToWidthOfViewport () {
      if (typeof this.desktopImageWidth !== 'string' || this.desktopImageWidth === '') {
        return 50
      }
      if (this.mobileImageWidth.endsWith('vw')) {
        return parseFloat(this.mobileImageWidth.slice(0, -2))
      }
      if (this.mobileImageWidth.endsWith('%')) {
        return parseFloat(this.mobileImageWidth.slice(0, -1))
      }
      return 50
    },
    cssVars () {
      return {
        '--mobile-image-width': this.mobileImageWidth || '120px',
        '--desktop-image-width': this.desktopImageWidth || '200px',
        '--aspect-ratio-mobile': this.aspectRatioMobile || '4/5',
        '--aspect-ratio-desktop': this.aspectRatioDesktop || '4/5',
        '--bg-color': this.bgColor || 'transparent',
        '--border-radius': this.borderRadius || '0'

      }
    }
  },
  updated () {
    this.$nextTick(this.recalculate)
  },
  mounted () {
    this.$el.addEventListener('mouseenter', this.setInteracted, { passive: true })
    this.$el.addEventListener('touchstart', this.setInteracted, { passive: true })
    this.$nextTick(this.recalculate)
  },
  unmounted () {
    this.$el.removeEventListener('mouseenter', this.setInteracted, { passive: true })
    this.$el.removeEventListener('touchstart', this.setInteracted, { passive: true })
  },
  methods: {
    setInteracted () {
      this.haveInteracted = true
    },
    handleScroll () {
      this.recalculate()
      this.setInteracted()
    },
    recalculate () {
      if (!this.$refs.slideContainer) {
        setTimeout(this.recalculate, 100)
        return
      }

      const { scrollLeft, clientWidth, scrollWidth } = this.$refs.slideContainer
      this.isOnStart = scrollLeft === 0
      this.isOnEnd = Math.round(scrollLeft) + clientWidth >= scrollWidth
    },
    scrollRight () {
      if (this.isOnEnd) {
        return
      }

      const { scrollLeft, clientWidth, scrollWidth } = this.$refs.slideContainer
      this.performScroll(Math.min(scrollLeft + clientWidth, scrollWidth - clientWidth))
      this.setInteracted()
    },
    scrollLeft () {
      if (this.isOnStart) {
        return
      }

      const { scrollLeft, clientWidth } = this.$refs.slideContainer
      this.performScroll(Math.max(scrollLeft - clientWidth, 0))
      this.setInteracted()
    },
    performScroll (position) {
      this.$refs.slideContainer.scroll({
        left: position,
        behavior: 'smooth'
      })
    },
    convertToProxy (image) {
      return this.$imgproxy.transform(image, 'rs:fit:80/g:nowe/q:70')
    }
  }
}
